@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  html{
    font-family: 'Poppins', sans-serif;
  }
  body{
    @apply bg-black-100;
    @apply text-black-500
  }
  th{
    @apply py-3;
    @apply px-2;
  }
  td{
    @apply py-4;
    @apply px-2;
  }
  tr{
    @apply border-b;
    @apply border-black-200;
  }
}
